import { useCallback, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import clsx from 'clsx';

import { usePiP } from './PiPProvider';
import { getFileUrl } from '../../../file';
import { drawText } from '../../lib/sourceText';
import { OrientationBaseSizes } from '../../lib/layouts';
import './PiPOverlay.scss';

const clampPipPosition = (pipPosition, layerBounds) => {
	//We want the pip to be able to get its half extents out of the stream
	if (pipPosition.x < (pipPosition.width / 2) * -1) {
		pipPosition.x = (pipPosition.width / 2) * -1;
	}
	if (pipPosition.x > layerBounds.width - (pipPosition.width / 2)) {
		pipPosition.x = layerBounds.width - (pipPosition.width / 2);
	}

	if (pipPosition.y < (pipPosition.height / 2) * -1) {
		pipPosition.y = (pipPosition.height / 2) * -1;
	}
	if (pipPosition.y > layerBounds.height - (pipPosition.height / 2)) {
		pipPosition.y = layerBounds.height - (pipPosition.height / 2);
	}
	return pipPosition;
};

export const PlayerLivePiPOverlay = ({
	isGfxInEdition,
	onMovingPiP,
	onUpdatePiP,
	orientation,
	pip,
	size,
}) => {
	const { keepRatio } = usePiP();
	const overlayRef = useRef(undefined);
	const canvasRef = useRef();
	const videoRef = useRef();
	// Used to display not yet uploaded images (when creating an image gfx for example)
	const isBlob = (pip?.asset?.filename || '').startsWith('blob:');

	const layerBounds = OrientationBaseSizes[orientation];

	const scaleFactor = useMemo(() => ({
		horizontal: (size.width / layerBounds.width) || 1,
		vertical: (size.height / layerBounds.height) || 1,
	}), [layerBounds, size]);

	const pipRealPosition = useMemo(() => {
		const { properties } = pip;
		return {
			x: properties.x * scaleFactor.horizontal,
			y: properties.y * scaleFactor.vertical,
			width: properties.width * scaleFactor.horizontal,
			height: properties.height * scaleFactor.vertical,
		};
	}, [pip, scaleFactor]);

	const updatePip = useCallback((data) => {
		const { properties } = pip;

		const updatedProperties = clampPipPosition({
			x: data.x ? Math.round(data.x / scaleFactor.horizontal) : properties.x,
			y: data.y ? Math.round(data.y / scaleFactor.vertical) : properties.y,
			width: data.width ? Math.round(data.width / scaleFactor.horizontal) : properties.width,
			height: data.height ? Math.round(data.height / scaleFactor.vertical) : properties.height,
		});

		onUpdatePiP(updatedProperties);
	}, [
		onUpdatePiP,
		pip,
		scaleFactor.horizontal,
		scaleFactor.vertical,
	]);

	const handleDragStop = useCallback((event, data) => {
		onMovingPiP(false);
		updatePip(data);
	}, [onMovingPiP, updatePip]);

	const handleResize = useCallback((event, _1, el, delta, position) => {
		updatePip({
			width: el.offsetWidth,
			height: el.offsetHeight,
			x: position.x,
			y: position.y,
		});
	}, [updatePip]);

	const handleResizeStop = useCallback((event, _1, el, delta, position) => {
		onMovingPiP(false);
		if (!isGfxInEdition) handleResize(event, _1, el, delta, position);
	}, [handleResize, isGfxInEdition, onMovingPiP]);

	useEffect(() => {
		if (isGfxInEdition && pip.type === 'text' && canvasRef.current) {
			const ctx = canvasRef.current.getContext('2d');
			canvasRef.current.width = pip.properties.width;
			canvasRef.current.height = pip.properties.height;
			drawText(pip.asset, ctx);
		}
	}, [isGfxInEdition, pip]);

	return (
		<Rnd
			default={{
				x: pipRealPosition.x,
				y: pipRealPosition.y,
			}}
			position={{
				x: pipRealPosition.x,
				y: pipRealPosition.y,
			}}
			size={{
				width: pipRealPosition.width,
				height: pipRealPosition.height,
			}}
			enableResizing
			lockAspectRatio={pip.type === 'text' || pip.type === 'gfx' ? false : keepRatio}
			minWidth={pip.properties.minWidth * scaleFactor.horizontal}
			minHeight={pip.properties.minHeight * scaleFactor.vertical}
			maxWidth={pip.properties.maxWidth * scaleFactor.horizontal}
			maxHeight={pip.properties.maxHeight * scaleFactor.vertical}
			onDragStart={() => onMovingPiP(true)}
			onDragStop={handleDragStop}
			onResize={isGfxInEdition ? handleResize : undefined}
			onResizeStart={() => onMovingPiP(true)}
			onResizeStop={handleResizeStop}
		>
			<div
				ref={overlayRef}
				className={clsx('overflow-auto border', isGfxInEdition ? 'PiPOverlay_gfx' : 'PiPOverlay_div')}
				id={pip.id}
			>
				{isGfxInEdition && pip.type === 'text' && (
					<canvas
						className="position-absolute"
						ref={canvasRef}
						style={{ zIndex: -1, width: '100%', height: '100%' }}
					/>
				)}
				{isGfxInEdition && pip.type === 'gfx' && (
					<img
						className="PiPOverlay_imgPreview w-100 h-100"
						src={isBlob ? pip.asset.filename : getFileUrl({ name: pip.asset.filename })}
					/>
				)}
				{isGfxInEdition && pip.type === 'gfxvideo' && (
					<video
						onCanPlay={() => {
							videoRef.current.currentTime = videoRef.current.duration / 2;
						}}
						ref={videoRef}
						className="w-100 h-100"
						src={isBlob ? pip.asset.filename : getFileUrl({ name: pip.asset.filename })}
					/>
				)}
			</div>
		</Rnd>
	);
};

PlayerLivePiPOverlay.propTypes = {
	isGfxInEdition: PropTypes.bool,
	onMovingPiP: PropTypes.func.isRequired,
	onUpdatePiP: PropTypes.func.isRequired,
	orientation: PropTypes.string,
	size: PropTypes.shape({
		right: PropTypes.number,
		height: PropTypes.number,
		width: PropTypes.number,
	}).isRequired,
	pip: PropTypes.shape({
		asset: PropTypes.shape({
			filename: PropTypes.string,
		}),
		id: PropTypes.string,
		properties: PropTypes.shape({
			minWidth: PropTypes.number,
			minHeight: PropTypes.number,
			maxWidth: PropTypes.number,
			maxHeight: PropTypes.number,
			x: PropTypes.number,
			y: PropTypes.number,
			width: PropTypes.number,
			height: PropTypes.number,
		}),
		layer: PropTypes.string,
		type: PropTypes.string,
		isGfxInEdition: PropTypes.bool,
	}).isRequired,
};

PlayerLivePiPOverlay.defaultProps = {
	isGfxInEdition: false,
	orientation: 'LANDSCAPE',
};
