// @ts-check

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { FaBroadcastTower, FaCalendar, FaCog, FaListUl, FaLock, FaPlay } from 'react-icons/fa';

import { Path, getLink } from '../../RoutePath';
import { ButtonPill } from '../../components/Button';
import { useCurrentStudio } from '../../components/Studio/useCurrentStudio';
import { StudioScheduleModal, useStudioSchedule } from '../../components/StudioSchedule/Context';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';
const isComvision = import.meta.env.VITE_PROJECT === 'comvision';

export const StudioHeaderStudioItems = () => {
	const { t } = useTranslation();
	const location = useLocation();

	const {
		openModal,
	} = useStudioSchedule();

	const {
		currentStudio,
		isCurrentStudioStarted,
		isCurrentStudioMine,
		isCurrentStudioOperatedByMe,
	} = useCurrentStudio();

	const openScheduleModal = (options = {}) => {
		openModal(
			StudioScheduleModal.EDIT,
			undefined,
			undefined,
			{ forceStartImmediately: true, ...options },
		);
	};

	const handleClick = () => {};

	const isControlledStudioStarted = isCurrentStudioStarted
		&& (isCurrentStudioMine || isCurrentStudioOperatedByMe);

	return (
		<div
			className="p-2 bg-darker rounded-2 border border-secondary"
		>
			{!isControlledStudioStarted && isBeeyou && (
				<ButtonPill
					color="primary"
					className="shadow-none w-100 mb-1 rounded-1"
					onClick={() => openScheduleModal({ isPublic: true })}
					size="sm"
				>
					<FaBroadcastTower className="icon mr-2" />
					{t('SidebarMenu.Index.startPublicLive')}
				</ButtonPill>
			)}
			{!isControlledStudioStarted && (
				<ButtonPill
					color={isBeeyou ? 'neutral-primary' : 'primary'}
					className="shadow-none w-100 mb-1 rounded-1"
					onClick={() => openScheduleModal()}
					size="sm"
				>
					<FaLock className="icon mr-2" />
					{isComvision ? t('CV.CommandCenter') : t('SidebarMenu.Index.privateMeeting')}
				</ButtonPill>
			)}
			{isControlledStudioStarted && (
				<ButtonPill
					color="neutral-primary"
					className="shadow-none w-100 mb-1 rounded-1"
					onClick={() => handleClick()}
					size="sm"
					tag={Link}
					to={getLink(Path.STUDIO)}
				>
					<FaPlay className="icon mr-2" />
					{isBeeyou ? t('SidebarMenu.Index.join') : t('CV.JoinCommandCenter')}
				</ButtonPill>
			)}
			<NavItem className="ml-0 mb-1">
				<NavLink
					className="m-0 pl-2"
					onClick={() => openModal(StudioScheduleModal.MANAGE)}
					tag={Link}
					to={location.pathname}
				>
					<FaListUl className="icon mr-2" />
					{t('SidebarMenu.Index.manage')}
				</NavLink>
			</NavItem>
			{isBeeyou && (
				<NavItem className="ml-0 mb-1">
					<NavLink
						className="m-0 pl-2"
						onClick={() => openModal(StudioScheduleModal.CALENDAR)}
						tag={Link}
						to={location.pathname}
					>
						<FaCalendar className="icon mr-2" />
						{t('SidebarMenu.Index.calendar')}
					</NavLink>
				</NavItem>
			)}
			{isControlledStudioStarted && (
				<NavItem className="ml-0 mb-1">
					<NavLink
						className="m-0 pl-2"
						onClick={() => openModal(StudioScheduleModal.EDIT, currentStudio?._id)}
						tag={Link}
						to={location.pathname}
					>
						<FaCog className="icon mr-2" />
						{t('SidebarMenu.Index.controller')}
					</NavLink>
				</NavItem>
			)}
		</div>
	);
};
