import PropTypes from 'prop-types';
import { Suspense, useCallback, useMemo, useState } from 'react';

import { useFetchGraphGlobalsPrices } from '../../api-hooks/graph/globals';
import { usePoints } from '../Membership/Points';
import { PaginationProvider } from '../Pagination/Provider';
import { StudioScheduleCalendarModal } from './Calendar/Modal/Modal';
import { StudioScheduleContext, StudioScheduleModal } from './Context';
import { StudioScheduleManagerEditModal } from './Manager/EditModal';
import { StudioScheduleManagerEstimation } from './Manager/Estimation';
import { StudioScheduleManagerModal } from './Manager/Modal/Modal';

const defaultFormData = {
	isPublic: false,
	isRecordEnabled: false,
};

const DEFAULT_PRICES = {
	studioMinute: 2,
	studioMinuteIfRecording: 6,
	viewerMinute: 1,
	participantMinute: 1,
	studioStart: 6,
};

export const StudioScheduleProvider = ({ children }) => {
	const { balanceWithChips } = usePoints();
	const [modalOpen, setModalOpen] = useState();
	const [modalAlertMessage, setModalAlertMessage] = useState();
	const [modalOptions, setModalOptions] = useState();
	const [modalEstimationOpen, setModalEstimationOpen] = useState();
	const [formData, setFormData] = useState(defaultFormData);
	const [studioInEditionId, setStudioInEditionId] = useState();
	const [studioOwnerId, setStudioOwnerId] = useState();

	const openModal = useCallback((modalType, studioId, message, options) => {
		setModalOpen(modalType);
		setModalAlertMessage(message);
		setModalOptions(options);
		setStudioInEditionId(studioId);
	}, []);
	const { data: PRICES = DEFAULT_PRICES } = useFetchGraphGlobalsPrices();

	const canStartStudio = (
		PRICES?.studioStart > 0
		&& balanceWithChips > 0
		&& PRICES.studioStart <= balanceWithChips
	);

	const closeModal = useCallback(() => {
		setModalOpen(undefined);
		setModalAlertMessage(undefined);

		if (modalOptions?.openCalendarAfterClose) {
			setModalOpen(StudioScheduleModal.CALENDAR);
		}
		setModalOptions(undefined);

		setStudioInEditionId(undefined);
		setModalEstimationOpen(false);
		setFormData(false);
	}, [modalOptions]);

	const openModalEstimation = useCallback((recordEnabled) => {
		setFormData(recordEnabled);
		setModalEstimationOpen(true);
	}, []);

	const closeModalEstimation = useCallback(() => {
		setModalEstimationOpen(false);
		setFormData(false);
	}, []);

	const context = useMemo(() => ({
		canStartStudio,
		closeModal,
		modalAlertMessage,
		modalOpen,
		openModal,
		setModalAlertMessage,
		modalEstimationOpen,
		openModalEstimation,
		closeModalEstimation,
		setModalOpen,
		setStudioInEditionId,
		studioInEditionId,
		PRICES,
	}), [
		canStartStudio,
		closeModal,
		modalAlertMessage,
		modalOpen,
		openModal,
		setModalAlertMessage,
		modalEstimationOpen,
		openModalEstimation,
		closeModalEstimation,
		setModalOpen,
		setStudioInEditionId,
		studioInEditionId,
		PRICES,
	]);

	return (
		<StudioScheduleContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === StudioScheduleModal.EDIT && (
					<StudioScheduleManagerEditModal
						isOpen
						openModal={openModal}
						options={modalOptions}
						studioOwnerId={studioOwnerId}
					/>
				)}
				{modalOpen === StudioScheduleModal.MANAGE && (
					<PaginationProvider>
						<StudioScheduleManagerModal
							defaultTab={modalOptions?.defaultTab}
							isOpen
							studioOwnerId={studioOwnerId}
							setStudioOwnerId={setStudioOwnerId}
						/>
					</PaginationProvider>
				)}
				{modalOpen === StudioScheduleModal.CALENDAR && (
					<StudioScheduleCalendarModal
						isOpen
						studioOwnerId={studioOwnerId}
						setStudioOwnerId={setStudioOwnerId}
						options={modalOptions}
					/>
				)}
				{modalEstimationOpen && (
					<StudioScheduleManagerEstimation
						isOpen
						onClose={closeModalEstimation}
						closeAllModals={closeModal}
						formData={formData}
					/>
				)}
			</Suspense>
		</StudioScheduleContext.Provider>
	);
};

StudioScheduleProvider.propTypes = {
	children: PropTypes.node,
};

StudioScheduleProvider.defaultProps = {
	children: undefined,
};
